import React from "react";
// reactstrap components
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <Row className="justify-content-center">
              <Col lg="4">
                <h5>千展雲網有限公司</h5>
                <p>
                407563 台中市西屯區市政北一路77號12樓之9<br />
                Rm. 9, 12F., No. 77, Shizheng N. 1st Rd., Xitun Dist., Taichung City 407563 , Taiwan (R.O.C.)<br />
                  {/* <abbr title="Phone">Phone:</abbr> (123) 456-7890<br />
                  <abbr title="Email">Mail:</abbr> abc@excom.com<br />  */}
                </p>
              </Col>
              <Col lg="2">
                <h5>聯絡我們</h5>
                <Nav vertical>
                  <NavItem>
                    <NavLink href="#contact-us">聯絡我們</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#join-us">加入我們</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="2">
                <h5>更多的了解</h5>
                <Nav vertical>
                  <NavItem>
                    <NavLink href="#products">產品介紹</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#cases">案例介紹</NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="2">
                <h5>網站政策</h5>
                <Nav vertical>
                  <NavItem>
                    <NavLink href="#terms-of-use">網站使用政策</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#privacy-policy">隱私權政策</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5">
              <Col lg="10">
                <div className="google-map-code" style={{ width: "100%", height: "250px", position: "relative" }}>
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3640.390172937408!2d120.64333250000001!3d24.1580455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693d932997a3ab%3A0x13a679073a136622!2zNDA35Y-w5Lit5biC6KW_5bGv5Y2A5biC5pS_5YyX5LiA6LevNzfomZ8!5e0!3m2!1szh-TW!2stw!4v1709517966025!5m2!1szh-TW!2stw"
                    style={{ border: 0, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="justify-content-center">
              <Col lg="12" className="text-center">
                <div>
                  © {new Date().getFullYear()}{" "}
                  <a href="https://www.infitech.com.tw">
                    千展雲網有限公司
                  </a>
                  . All rights reserved.
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;