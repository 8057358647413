import React from "react";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
// nodejs library that concatenates classes
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

// reactstrap components
import {
  CardImg,
  Badge,
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";

// core components
import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../components/Footers/SimpleFooter.js";
import Hero from "./IndexSections/Hero.js";


class Index extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      nameFocused: false,
      emailFocused: false,
      recaptchaValue: null,
    };
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onReCAPTCHAVerified = (token) => {
    console.log('reCAPTCHA token:', token);
    this.setState({ recaptchaValue: token });
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message, recaptchaValue } = this.state;

    if (!recaptchaValue) {
      alert('請先完成驗證。');
      return;
    }

    if (!name.trim() || !email.trim() || !message.trim()) {
      alert('請填寫完成所有的欄位');
      return;
    }

    if (!this.validateEmail(email)) {
      alert('請輸入有效的電子郵件地址。');
      return;
    }

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await fetch(`${API_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        console.log('已收到您的需求，謝謝您，請耐心等候。');
        this.setState({
          name: '',
          email: '',
          message: '',
        });
        alert('已收到您的需求，謝謝您，請耐心等候。');
      } else {
        console.error('送出失敗，請稍後再嘗試...', data.message);
        alert('送出失敗，請稍後再嘗試...: ' + data.message);
      }
    } catch (error) {
      console.error('發生錯誤，請稍後再嘗試', error);
      alert('發生錯誤，請稍後再嘗試: ' + error.message);
    }
  }


  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <Hero />
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            客製化設計
                          </h6>
                          <p className="description mt-3">
                            提供一站式客製化設計服務，從概念到實現，滿足您的所有需求。
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              創意
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              專業
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              高效
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            了解更多
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            數位行銷
                          </h6>
                          <p className="description mt-3">
                            提供全方位數位行銷解決方案，幫助您的業務成長。
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              SEO
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              社群媒體
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              內容行銷
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            了解更多
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            技術支援
                          </h6>
                          <p className="description mt-3">
                            提供專業的技術支援服務，確保您的業務運行無憂。
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              24/7
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              快速響應
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              專業團隊
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            了解更多
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section bg-secondary" id="about-us">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="關於我們的圖片"
                      src={require("../assets/img/theme/business-business-people-collaboration-communication-thumbnail.jpg")} // 更換為您的關於我們的圖片路徑
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        關於我們
                      </h4>
                      <p className="lead text-italic text-white">
                        我們是一支充滿熱情與創新的團隊，致力於提供最佳的解決方案，幫助客戶實現目標。
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-circle-08" />
                    </div>
                    <h3>我們的使命</h3>
                    <p className="lead">
                      致力於創造價值，通過我們的專業知識和經驗，幫助每一位客戶成功。
                    </p>
                    <p>
                      我們相信，透過持續的學習和創新，我們能夠提供最前沿的技術和服務，解決客戶面臨的挑戰。
                    </p>
                    <p>
                      我們的團隊由一群充滿激情的專業人士組成，他們擁有豐富的行業經驗和專業知識，準備好與您一起創造未來。
                    </p>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      了解更多關於我們
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-0 bg-gradient-warning" id="products" style={{ minHeight: '700px' }}>
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="text-white">全面的企業解決方案</h2>
                  <p className="text-white">
                    我們提供從雲端服務到POS系統，再到報表、會員以及OA內部系統的全方位解決方案，旨在支持您的業務成長與數字化轉型。
                  </p>
                </Col>
              </Row>
              <Row>
                {/* 卡片1 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0 deep-shadow">
                    <CardBody>
                      <h5 className="title text-primary">雲端服務</h5>
                      <p>提供全面的雲端計算解決方案，包括雲存儲、雲服務器和雲數據庫等服務。</p>
                    </CardBody>
                  </Card>
                </Col>
                {/* 卡片2 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0 deep-shadow">
                    <CardBody>
                      <h5 className="title text-success">POS系統</h5>
                      <p>提供靈活且強大的POS系統，支持銷售、庫存管理到客戶關係管理。</p>
                    </CardBody>
                  </Card>
                </Col>
                {/* 卡片3 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody>
                      <h5 className="title text-info">報表系統</h5>
                      <p>即時數據分析和報告，幫助您做出更快更準確的業務決策。</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                {/* 卡片4 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody>
                      <h5 className="title text-warning">會員系統</h5>
                      <p>強大的會員管理功能，支持會員資料管理、積分系統等，提升客戶忠誠度。</p>
                    </CardBody>
                  </Card>
                </Col>
                {/* 卡片5 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody>
                      <h5 className="title text-danger">OA內部系統</h5>
                      <p>為企業內部管理提供全面解決方案，包括文件管理、任務分配等功能。</p>
                    </CardBody>
                  </Card>
                </Col>
                {/* 卡片6 */}
                <Col lg="4" md="6" className="mb-4 mb-lg-0">
                  <Card className="card-lift--hover shadow border-0 deep-shadow">
                    <CardBody>
                      <h5 className="title text-primary">客戶支持</h5>
                      <p>提供全天候客戶支持服務，確保您的業務運營順暢無憂。</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 150"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 150 0 150"
                  />
                </svg>
              </div>
            </Container>
          </section>

          <section className="section section-lg" id="cases">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">客戶實際案例</h2>
                  <p className="lead text-muted">
                    我們為來自不同行業的客戶提供定制化解決方案，幫助他們實現業務目標和數字化轉型。
                  </p>
                </Col>
              </Row>
              <Row>
                {/* 案例1 */}
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../assets/img/theme/online-shopping-4532460_1280.jpg")}
                      tyle={{ width: "200px", height: "185px"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">電子商務擴展</span>
                        <small className="h6 text-muted">電子商務</small>
                      </h5>
                      <p>通過我們的解決方案，客戶實現了在線銷售額的顯著增長。</p>
                    </div>
                  </div>
                </Col>
                {/* 案例2 */}
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../assets/img/theme/board-3699939_960_720.png")}
                      tyle={{ width: "200px", height: "185px"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">技術創新</span>
                        <small className="h6 text-muted">科技公司</small>
                      </h5>
                      <p>幫助科技公司通過創新解決方案優化產品開發流程。</p>
                    </div>
                  </div>
                </Col>
                {/* 案例3 */}
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../assets/img/theme/istockphoto-1608073055-170667a.jpg")}
                      tyle={{ width: "100px", height: "200px", borderRadius: "50%"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">市場擴張</span>
                        <small className="h6 text-muted">零售業</small>
                      </h5>
                      <p>為零售業客戶提供市場分析，助力其成功進入新市場。</p>
                    </div>
                  </div>
                </Col>
                {/* 案例4 */}
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("../assets/img/theme/triangle-3125882_1280.jpg")}
                      style={{ width: "200px", height: "165px"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">運營效率提升</span>
                        <small className="h6 text-muted">製造業</small>
                      </h5>
                      <p>通過自動化解決方案，幫助製造業客戶顯著提高生產效率和降低成本。</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg bg-gradient-default" id="contact-us">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">讓我們開始吧！</h2>
                  <p className="lead text-white">
                    我們的合作流程簡單明瞭，旨在為您提供最適合的解決方案。
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                {/* 需求接收 */}
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-email-83 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">需求接收</h5>
                  <p className="text-white mt-3">
                    您只需通過電子郵件或電話告知我們您的需求，我們的團隊將迅速與您取得聯繫。
                  </p>
                </Col>
                {/* 评估方案 */}
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-chart-bar-32 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">評估方案</h5>
                  <p className="text-white mt-3">
                    我們的專家將根據您的需求進行詳細評估，並提出最合適的解決方案。
                  </p>
                </Col>
                {/* 开始制作 */}
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-laptop text-primary" />
                  </div>
                  <h5 className="text-white mt-3">開始製作</h5>
                  <p className="text-white mt-3">
                    一旦方案得到您的確認，我們的團隊將開始著手實施，確保按時交付高質量成果。
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  {/* 表單區域 */}
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <FormGroup
                        className={classnames({
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="姓名"
                            type="text"
                            name="name"
                            onFocus={(e) => this.setState({ nameFocused: true })}
                            onBlur={(e) => this.setState({ nameFocused: false })}
                            onChange={this.handleChange}
                            value={this.state.name}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onFocus={(e) => this.setState({ emailFocused: true })}
                            onBlur={(e) => this.setState({ emailFocused: false })}
                            onChange={this.handleChange}
                            value={this.state.email}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          placeholder="請輸入您的需求..."
                          rows="4"
                          type="textarea"
                          onChange={this.handleChange}
                          value={this.state.message}
                        />
                      </FormGroup>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ReCAPTCHA
                         sitekey="6LfO2YgpAAAAAMv_uxLgvDNNiVy7RJz6pyrwubWp" 
                         onChange={this.onReCAPTCHAVerified} 
                      />
                      </div>
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="button" onClick={this.handleSubmit}>
                          完成送出
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Index;

