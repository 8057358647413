import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Collapse,
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
  }

  state = {
    collapseOpen: false,
  };

  toggleNavbar = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      collapseOpen: !prevState.collapseOpen,
    }));
  };

  closeCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        collapseOpen: false,
      });
    }
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar className="navbar-main navbar-transparent navbar-light headroom" expand="lg" id="navbar-main">
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <h4 className="text-white">千展雲網有限公司</h4>
              </NavbarBrand>
              <button className="navbar-toggler" onClick={this.toggleNavbar}>
                <span className="navbar-toggler-icon" />
              </button>
              <Collapse isOpen={this.state.collapseOpen} navbar>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                    <NavLink href="#about-us" onClick={this.closeCollapse}>關於我們</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#cases" onClick={this.closeCollapse}>案例</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#products" onClick={this.closeCollapse}>產品</NavLink>
                    </NavItem>
                  <NavItem>
                    <NavLink href="#contact-us" onClick={this.closeCollapse}>聯絡我們</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;